import React from 'react';
import { BrowserRouter } from 'react-router-dom'

import { render } from 'react-dom';
import { Provider } from 'react-redux';

import AppCheckout from './AppCheckout';
import FontStyles from './fontStyles';

import {store} from './store'
import GlobalStyle from './GlobalStyle';

render((
  <Provider store={store} >
    <BrowserRouter>
      <FontStyles />
      <GlobalStyle />
      <AppCheckout />
    </BrowserRouter>
  </Provider>
  ), document.getElementById('app-checkout'));
