import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    flex-flow: column;
    max-width: 540px;
    padding: 1rem ;
    margin: 0 auto;
    font-family: 'Montserrat';
`

export const Title = styled.div`
    padding: 0rem ;
    color:#002970;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
`

export const BoxMethod = styled.div`
    padding: 0rem ;
    margin-top: 24px;
`

export const MsgError = styled.div`
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 4px;  
    background-color: #fcf8e3;
    display: flex;
    img{
        width: 26px;
    }
    p{
        color: #8a6d3b;
        border-color: #faebcc;
        margin:0;
        padding:0;
        line-height:28px;
        text-indent:5px;
    }
`
