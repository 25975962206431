import { createSlice } from '@reduxjs/toolkit'
import { fetchPaymentMethod } from '../actions/paymentActions'

const initialState = {
    items: [],
    loading: false,
}

export const methodsSlice = createSlice({
  name: 'methods',
  initialState,
  reducers: {
   
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchPaymentMethod.fulfilled, (state, action) => {
      // Add user to the state array
      state.items = action.payload.result
    })

},
})

// Action creators are generated for each case reducer function
export const { 

 } = methodsSlice.actions

export default methodsSlice.reducer

export const selectMethodItems = (state) => state.methods.items || [];