// store.js
import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducers'
import {createLogger} from 'redux-logger'

const middlewares =[];

if (process.env.NODE_ENV === 'development') {
    middlewares.push(createLogger());
}

export const store = configureStore({ 
    reducer: rootReducer ,
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(middlewares)
})
