import styled, {keyframes} from "styled-components"

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;


export const ItemMethod = styled.div`
    display: flex;
    cursor: pointer;
    border: 1px solid #d9d9d99c;
    border-radius:.5rem;
    color:#002970;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 24px;
    background-color: #FFFFFF;

    -webkit-transition : border 700ms ease-in-out;
    -moz-transition : border 700ms ease-in-out;
    -o-transition : border 700ms ease-in-out;
    transition : border 700ms ease-in-out;

    -webkit-transition : background-color 800ms ease-in-out;
    -moz-transition : background-color 800ms ease-in-out;
    -o-transition : background-color 800ms ease-in-out;
    transition : background-color 800ms ease-in-out;

    animation: ${fadeIn} 1.5s ease-in-out;

    &.active {
        border: 1px solid rgba(5, 152, 237);
        background-color:rgba(243,248,251,.7)
    }

    .content{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 7px;
    }

    .decs-label-by{
        color: #595959;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }
`

export const ItemMethodTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #002970;
`

export const ItemMethodLogo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 4rem;
    padding: .25rem;
    text-align: center;
    img {
        max-width: 100%;
        height: auto;
        vertical-align: middle;
    }
`

export const ItemMethodTitleLogo = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #262626;
    margin-left:5px;
`

export const ButtonPayment = styled.button`
    width:100%;
    pointer-events:${(props)=>props.disabled?'none':null};
    border-radius: 0.25rem;
    padding: 1rem ;
    margin-top: 1.25rem;
    color: white;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    background-color: #ED1C24;
    text-align: center;
    cursor: pointer;
    border: none;

    font-family: 'Montserrat';
    :disabled {
        opacity: 0.75;
        animation: ${fadeIn} 1.5s ;
    }
`

export const ItemPowered = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    row-gap: 10px;
    margin-top: 5px;
    .decs-label-by{
        color: #595959;
        font-weight: 500;
        font-size: 11px;
        line-height: 20px;
    }
    img{
        width: 22px;
        margin-left:10px
    }
`

export const ItemOrder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
`

export const LabelOrder = styled.div`
    font-weight: 500;
    color: #212121;
    font-size: .875rem;
`

export const ValueOrder = styled.div`
    font-weight: 500;
    color: #262626;
    letter-spacing: 1px;
    font-size: .875rem;
`

export const AmountOrder = styled.div`
    font-weight: 700;
    color: #002970;
    letter-spacing: .75px;
    font-size: .875rem;
`

export const HrOrder = styled.div`
    font-size: .875rem;
    border-width: 1px;
    border-style: dashed;
    border-color: #bfbfbf;
    height:0;
    margin-top: 20px;
    margin-bottom: 4px;
`
