
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {PulseLoader} from "react-spinners";
import { fetchAppID, fetchPaymentMethod } from "../../actions/paymentActions";
  
const Loading = () => {

    return (
        <PulseLoader
            color={"#36d7b7"}
            loading={true}
            size={10}
            speedMultiplier={.5}
        />
    );
}

export default Loading;