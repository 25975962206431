import { createGlobalStyle } from "styled-components";
import RobotoWoff from "./assets/fonts/montserrat/Montserrat-Regular.woff";
import RobotoWoff2 from "./assets/fonts/montserrat/Montserrat-Medium.woff";
import RobotoWoff3 from "./assets/fonts/montserrat/Montserrat-SemiBold.woff";

const FontStyles = createGlobalStyle`

    @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        src: url(${RobotoWoff}) format('woff');
    }

    @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        src: url(${RobotoWoff2}) format('woff');
    }

    @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        src: url(${RobotoWoff3}) format('woff');
    }
    
`;

export default FontStyles;