import React from "react"
import { ButtonPayment } from "./styled"

const BtnCheckout = ({ checkout ,activeMethod }) => {
  return (
    <ButtonPayment disabled={activeMethod ? false : true } onClick={e=>{checkout()}}  >
      Thanh toán
    </ButtonPayment>
   
  );
};

export default BtnCheckout;
