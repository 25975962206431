import { createAsyncThunk } from "@reduxjs/toolkit";
import { merchantService } from "../services/merchant.service";

export const fetchAppID = createAsyncThunk(
  "merchant/fetchAppID",
  async (params, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await merchantService.getAppIDSignature(params);
      return response.data;
    } catch (err) {
      if (!err.error_code) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const fetchPaymentMethod = createAsyncThunk(
  "merchant/fetchPaymentMethod",
  async (params, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await merchantService.getPaymentMethod(params);
      return response.data;
    } catch (err) {
      if (!err.error_code) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const fetchOrderIDTransaction = createAsyncThunk(
  "merchant/fetchOrderIDTransaction",
  async (params, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await merchantService.getOrderIDTransaction(params);
      return response.data;
    } catch (err) {
      if (!err.error_code) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const createURLPayment = createAsyncThunk(
  "merchant/createURLPayment",
  async (params, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await merchantService.createURLPayment(params);
      return response.data;
    } catch (err) {
      if (!err.error_code) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const createSignature = createAsyncThunk(
  "signature/createSignature",
  async (params, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await merchantService.createSignature(params);
      return response.data;
    } catch (err) {
      if (!err.error_code) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);

export const createSignatureVPBank = createAsyncThunk(
  "signature/createSignatureVPBank",
  async (params, { dispatch, getStat, rejectWithValue }) => {
    try {
      const response = await merchantService.createSignatureVPBank(params);
      return response.data;
    } catch (err) {
      if (!err.error_code) {
        throw err;
      }
      return rejectWithValue(err);
    }
  }
);
