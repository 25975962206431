
import { combineReducers } from '@reduxjs/toolkit'
import methods from './methodsSlice'
import merchant from './merchantSlice'
import order from './orderSlice'


const rootReducer = combineReducers({
    merchant,
    order,
    methods
})

export default rootReducer;

