import React from "react"
import { formatCurrency } from "../../utils/lib"
import { ItemOrder, LabelOrder, ValueOrder ,AmountOrder} from "./styled"

const OrderInfo = ({data}) => {
    
    return(
        <div className="flex flex-col space-y-4">
            <ItemOrder>
                <LabelOrder>Đơn vị chấp nhận thanh toán:</LabelOrder>
                <ValueOrder>
                {data?.merchant_name}
                </ValueOrder>
            </ItemOrder>
            <ItemOrder>
                <LabelOrder>Mã đơn hàng:</LabelOrder>
                <ValueOrder>
                    {data?.order_id}
                </ValueOrder>
            </ItemOrder>
            <ItemOrder>
                <LabelOrder>Số tiền thanh toán:</LabelOrder>
                <AmountOrder>
                    {formatCurrency(data?.total_amount) }
                </AmountOrder>
            </ItemOrder>
        </div>
    )
}

export default OrderInfo