
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { selectOrderInfo } from '../../reducers/orderSlice';
import { selectMethodItems } from '../../reducers/methodsSlice';

import { createURLPayment,  fetchOrderIDTransaction, fetchPaymentMethod } from '../../actions/paymentActions';

import { BoxMethod, BoxPayment,  ContainerPage, Title } from "./styled";
import { HrOrder } from '../../components/checkout/styled';

import LoadingPage from '../../components/loading';
import PaymentMethod from '../../components/checkout/PaymentMethod';
import BtnCheckout from '../../components/checkout/BtnCheckout';
import OrderInfo from '../../components/checkout/OrderInfo';
import AlertMessage from '../../components/common/AlertMessage';

const KEY_ID_CHECKOUT_SACOMBANK = "63896905fdc876c9c9dcfe41"
const KEY_ID_CHECKOUT_QR = "63896944fdc876c9c9dcfe42"

const PaymentCheckout = () => {

    const dispatch = useDispatch()
    
    const {id} = useParams()

    const methods = useSelector(selectMethodItems)
    const order = useSelector(selectOrderInfo)

    const [activeMethod, setActiveMethod] = useState("")
    const [msg, setMsg] = useState("")

    useEffect(() => {
      if (id) {
        fetchOrderID(id)
      }
    }, [id])

    const fetchOrderID = async (_id) => {

      const data = await dispatch(fetchOrderIDTransaction({ _id }))

      if(data.payload.msg){
        setMsg(data.payload.msg)
      }

      if (data) {
          await dispatch(fetchPaymentMethod({}))
      }

    }
  
    const checkoutOrder = async () => {
      
      switch (activeMethod) {
        case KEY_ID_CHECKOUT_SACOMBANK:
          createSignPayment()
          break;

        case KEY_ID_CHECKOUT_QR:
          alert("coming soon!!")
          break;
      
        default:
          break;
          
      }

    };

    const createSignPayment = async () => {

      const dataSignature = await dispatch(createURLPayment({
        order: id
      }))

      if(dataSignature.payload && dataSignature.payload.url){
        window.location.href= dataSignature.payload.url
      }

    }

    if(msg){
      return <AlertMessage msg={msg} />
    }

    if(!order || !methods){
      return <LoadingPage />
    }

    return (
      <ContainerPage>

        <BoxPayment>

          <Title>Chọn phương thức thanh toán</Title>

          <OrderInfo data={order} />

          <HrOrder />

          {methods.map((item) => (
            <BoxMethod
              key={item._id}
              onClick={() => setActiveMethod(item._id)}
            >
              <PaymentMethod item={item} activeMethod={activeMethod} />
            </BoxMethod> 
          ))}

          {order && methods && methods.length > 0 && <BtnCheckout activeMethod={activeMethod} checkout={checkoutOrder} />}

        </BoxPayment>
        
      </ContainerPage>
    );


}
export default PaymentCheckout;

