import { createSlice } from "@reduxjs/toolkit"
import { fetchOrderIDTransaction } from '../actions/paymentActions'

const initialState = {
    data: null,
    error: null,
    loading: false
}

const orderSlice =createSlice({
    name:'order',
    initialState:initialState,
    reducers:{
        
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOrderIDTransaction.pending, (state, action) => {
            state.loading= true
            state.data= null
        })
        builder.addCase(fetchOrderIDTransaction.fulfilled, (state, action) => {
            state.loading= false
            state.data = action.payload.result
        })
        builder.addCase(fetchOrderIDTransaction.rejected, (state, action) => {
            state.loading= false
            state.error = action.payload
        })
    },
})

export const { 

} = orderSlice.actions

export default orderSlice.reducer;

// create and export the selector
export const selectOrderInfo = (state) => state.order.data

