import React from 'react';

import {
  Router,
  Switch
} from "react-router-dom"

import { history } from './utils/history'

import PaymentCheckout from './pages/checkout'
import MainLayout from './layouts/MainLayout'
import PaymentCheckoutQR from './pages/payment-qr';


const AppCheckout = () =>{
  return (
    <Router history={history} >
      <Switch>
        <MainLayout exact path="/payment/:id" component={PaymentCheckout} />
        <MainLayout exact path="/payment-qr" component={PaymentCheckoutQR} />
      </Switch>
    </Router>
  )
}

export default AppCheckout;