import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    body {
        font-size:.75rem;
        font-family: 'Montserrat';
        padding:0;
        margin:0;
    }
`;

export default GlobalStyle;