import { createSlice } from "@reduxjs/toolkit"
import { fetchAppID } from '../actions/paymentActions'

const initialState = {
    data: null,
    loading: false
}

const merchantSlice =createSlice({
    name:'merchant',
    initialState:initialState,
    reducers:{
        
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAppID.pending, (state, action) => {
            state.loading= true
            state.data= null
        })
        builder.addCase(fetchAppID.fulfilled, (state, action) => {
            state.loading= false
            state.data = action.payload.result
        })
        builder.addCase(fetchAppID.rejected, (state, action) => {
            state.loading= false
        })
    },
})

export const { 

} = merchantSlice.actions

export default merchantSlice.reducer;

// create and export the selector
export const selectMerchantInfo = (state) => state.merchant.data

