import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import { selectMethodItems } from "../../reducers/methodsSlice";
import { selectMerchantInfo } from "../../reducers/merchantSlice";

import { fetchAppID, fetchPaymentMethod } from "../../actions/paymentActions";

import { Container, Title } from "./styled";
import { BoxPayment } from "../checkout/styled";

import LoadingPage from "../../components/loading";
import OrderInfo from "../../components/checkout/OrderInfo";
import AlertMessage from "../../components/common/AlertMessage";
import axiosClient from "../../services/axiosClient";
import {alertError, alertInfo} from "../../utils/alert";

const PaymentCheckoutQR = ({ props }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const methods = useSelector(selectMethodItems);
    const merchant = useSelector(selectMerchantInfo);

    const [msg, setMsg] = useState("");
    const [qrCode, setQrCode] = useState(null)
    // const [iFrameUrl, setIFrameUrl] = useState("https://test-payment.momo.vn/v2/gateway/pay?t=TU9NT0RCSFEyMDIyMDQyMXxBVE8wMDAwMQ&s=a978cc5ce73ff22de6f594b80dea19f13263240131f29851605fee7eb9960a4d")
    const interval = useRef();
    
    const history = useHistory()

    useEffect(() => {
        // fetch metadata
        if (props && props.appID) {
            fetchMerchant(props.appID);
        }

        // fetch QR
        if(query.get('type').toUpperCase() === 'QR'){
            axiosClient({
                method: "POST",
                url:"/v1/checkout/payment-qr",
                data: {
                    order_id: query.get("order_id"),
                    amount: props.order?.amount
                }
            }).then(response => {
                if (response.data.error) {
                    alertError(response.data.msg)
                }else{
                    setQrCode(response.data.qr_base64)
                }
            })
        }
        // fetch momo
        if (query.get('type').toUpperCase() === 'MOMO'){
            axiosClient({
                method: "GET",
                url: '/v1/checkout/payment-momo',
                params: {
                    order_id: query.get("order_id"),
                    amount: props.order?.amount
                }
            }).then(response => {
                if (response.data.error) {
                    alertError(response.data.msg)
                }else{
                    setQrCode(response.data.image)
                }
            })
        }

    }, [props]);

    useEffect(() => {
        const loopCheckTxn = async () => {
          const status = await fetchTxnStatus();
          if(status === true) {
            alertInfo('Giao dịch thành công')
            clearInterval(interval.current);
            history.push("/payment-success")
          }
        };
    
        interval.current = setInterval(loopCheckTxn, 5000);
    
        return () => clearInterval(interval.current);
    
      }, [])
    

    const fetchTxnStatus = async () => {
        const response = await axiosClient({
            method: "GET",
            url: "/v1/checkout/txn",
            params: {"order_id": query.get("order_id")}
        })
        return response.data
    }

    const fetchMerchant = async (app_id) => {
        const data = await dispatch(fetchAppID({ app_id }));
        if (data.payload.msg) {
            setMsg(data.payload.msg);
        }
        if (data) {
            await dispatch(fetchPaymentMethod({}));
        }
    };

    if (msg) {
        return <AlertMessage msg={msg} />;
    }

    if (!merchant || !methods) {
        return <LoadingPage props={props} />;
    }

    return (
        <Container>
            <BoxPayment>
                <Title>Chọn phương thức thanh toán QR Code {query.get("type")}</Title>
                <OrderInfo
                    data={{
                        order_id: query.get("order_id"),
                        total_amount: props.order?.amount,
                        ...merchant,
                    }}
                />
            </BoxPayment>
            {query.get('type') === 'VCCB' &&<div style={{display:"flex", justifyContent: "space-around", marginTop: 20}}>
                <img width={200} src="https://camo.githubusercontent.com/8d9c7f6b8bec4ef4d5a43a1d30574129102a1be046f75edbe09c3bde7527fa7b/68747470733a2f2f7265732e636c6f7564696e6172792e636f6d2f7461736b6d616e616765726561676c6f623132332f696d6167652f75706c6f61642f76313634313937303939352f5669657451522e34366137386362625f7574777a7a682e706e67" alt="" />
                <img  width={200} src="https://inuvdp.com/wp-content/uploads/2022/05/logo-ban-viet-01.jpg" alt="" />
            </div>}
            {qrCode && <img src={"data:image/png;base64," + qrCode} alt="" />}
            {/* {iFrameUrl && query.get("type").toUpperCase() === 'MOMO' && <iframe id="momo" title="momo" style={{border: "none"}} width="1000" height="1000" src={iFrameUrl}></iframe>} */}
            
        </Container>
    );
};
export default PaymentCheckoutQR;
