import React from "react"
import icLogo from '../../assets/images/logo-atom.png'

import { ItemMethod, ItemMethodLogo, ItemMethodTitle, ItemMethodTitleLogo, ItemPowered } from "./styled"

const PaymentMethod = ({ item, activeMethod }) => {
  return (
    <ItemMethod 
    className={`${
      (  activeMethod === item._id)
          ? "active"
          : ""
      }`}
    >
      <div className="content space-x-2">
        <ItemMethodLogo> {item.logo && <img src={item.logo} alt="payment" />} </ItemMethodLogo>
        <div className="flex flex-col space-y-2">
          <ItemMethodTitle>
            {item.name}
          </ItemMethodTitle>
          <ItemPowered>
            <span className="decs-label-by">Powered by</span>
            <img src={icLogo} alt="logo" className="w-5 ml-2" />
            <ItemMethodTitleLogo>
              ATOM Solution
            </ItemMethodTitleLogo>
          </ItemPowered>
        </div>
      </div>
      {item && (
        <input
          type="radio"
          value={item._id}
          checked={activeMethod === item._id}
          readOnly={true}
          // name={item.name}
          className="form-radio text-blue-6 h-4 w-4 mt-1"
        />
      )}
    </ItemMethod>
  );
};

export default PaymentMethod;
